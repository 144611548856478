import { connect } from 'react-redux';

import component from 'photobooth/components/events';
import { fetchEvents, unfocusEvent } from 'photobooth/actions/events';
import { unfocusCheckin } from 'photobooth/actions/checkin';

function mapStateToProps(state) {
  return {
    events: state.events,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestEvents: () => dispatch(fetchEvents()),
    unfocusEvent: () => dispatch(unfocusEvent()),
    unfocusCheckin: () => dispatch(unfocusCheckin()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
