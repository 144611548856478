import PropTypes from 'prop-types';
import React from 'react';
import lodash from 'lodash-es';
import substantialLogo from 'photobooth/assets/images/new-logo-the-dude.svg';

import './styles.scss';

const interval = 5000;

export default class extends React.Component {
  static displayName = 'party-mode';

  static propTypes = {
    fetchNewCheckins: PropTypes.func.isRequired,
    focusedEvent: PropTypes.object.isRequired,
    partyMode: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    if (this.props.partyMode) {
      this.interval = setInterval(() => {
        const newestCheckin = lodash.maxBy(
          this.props.focusedEvent.event_checkins,
          checkin => new Date(checkin.created_at).getTime()
        );
        const newerThan = parseInt(
          new Date(newestCheckin.created_at).getTime() / 1000,
          10
        );
        this.props.fetchNewCheckins(
          this.props.focusedEvent.slug,
          newerThan + 1
        );
      }, interval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const { partyMode, focusedEvent: {logo, hashtag}} = this.props
    if (!partyMode) {
      return null
    }
    return (<div id="party-mode-overlay">
              <style>{"html {overflow: hidden !important;} body { background: black !important;}"}</style>
              <div id="party-mode-substantial-logo">
                <img src={substantialLogo} />
              </div>
              {hashtag && <div id="party-mode-hashtag">#{hashtag}</div>}
              {logo && <div id="party-mode-event-logo"><img src={logo} /></div>}
            </div>);
  }
}
