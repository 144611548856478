export const RESET_CHECKIN_TRANSITION = 'RESET_CHECKIN_TRANSITION';
export function resetCheckinTransition() {
  return {
    type: RESET_CHECKIN_TRANSITION,
  };
}

export const UNFOCUS_CHECKIN = 'UNFOCUS_CHECKIN';
export function unfocusCheckin() {
  return {
    type: UNFOCUS_CHECKIN,
  };
}

export const FOCUS_CHECKIN = 'FOCUS_CHECKIN';
export function focusCheckin(checkinId) {
  return function focusCheckinDispatcher(dispatch) {
    dispatch({
      type: FOCUS_CHECKIN,
      checkinId,
    });
  };
}
