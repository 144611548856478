import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './styles.scss';

class Header extends React.Component {
  static displayName = 'header';

  static propTypes = {
    focusedEvent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  };

  static defaultProps = {
    focusedEvent: undefined,
  };

  render() {
    const substantialPicsAnchor = (
      <a
        href="http://twitter.com/SubstantialPics"
        target="_blank"
        rel="noopener noreferrer"
      >
        @SubstantialPics
      </a>
    );
    let header = <h1 className="header-title">Substantial GIFBooth</h1>;
    let subHeader = (
      <h2 className="header-sub-header">Follow us {substantialPicsAnchor}</h2>
    );
    const visitLink = (
      <a className="header-visit-link" href="http://substantial.com">
        Visit Substantial.com
      </a>
    );
    if (this.props.focusedEvent) {
      if (this.props.focusedEvent.logo) {
        header = <img src={this.props.focusedEvent.logo} />;
        if (this.props.focusedEvent.url) {
          header = (
            <a
              href={this.props.focusedEvent.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {header}
            </a>
          );
        }
      } else {
        header = (
          <h1 className="header-title">
            <Link to={`/events/${this.props.focusedEvent.slug}`}>
              {this.props.focusedEvent.name}
            </Link>
          </h1>
        );
      }
      let subHeaderAnchor = substantialPicsAnchor;
      if (this.props.focusedEvent.hashtag) {
        subHeaderAnchor = (
          <a
            href={`https://twitter.com/hashtag/${
              this.props.focusedEvent.hashtag
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            #{this.props.focusedEvent.hashtag}
          </a>
        );
      }
      let displayDate = this.props.focusedEvent.formattedCreatedAt;
      if (this.props.focusedEvent.display_date) {
        displayDate = this.props.focusedEvent.display_date;
      }
      subHeader = (
        <h2 className="header-sub-header">
          <span className="header-sub-header-display-date">{displayDate}</span>
          <span className="header-sub-header-separator">&middot;</span>
          <span className="header-sub-header-anchor">{subHeaderAnchor}</span>
        </h2>
      );
    }
    const className = classnames({
      header: true,
      'event-logo': this.props.focusedEvent && this.props.focusedEvent.logo,
    });
    return (
      <div className={className}>
        <a className="header-logo" href="/">
          Return to gallery home
        </a>
        <div className="header-event-identity">{header}</div>
        {subHeader}
        {visitLink}
      </div>
    );
  }
}

export default Header;
