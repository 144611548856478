import PropTypes from 'prop-types';
import React from 'react';

import Checkin from 'photobooth/containers/checkin';
import Checkins from 'photobooth/components/checkins';
import DynamicStyles from 'photobooth/containers/dynamic-styles';
import ErrorBoundary from 'photobooth/components/error-boundary';

import './styles.scss';

export default class extends React.Component {
  static displayName = 'event';

  static propTypes = {
    fetchEvent: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.object.isRequired,
    }).isRequired,
    focusedEvent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    focusedCheckin: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    unfocusCheckin: PropTypes.func.isRequired,
    focusCheckin: PropTypes.func.isRequired,
  };

  static defaultProps = {
    focusedEvent: undefined,
    focusedCheckin: undefined,
  };

  componentWillMount() {
    this.props.fetchEvent(this.props.match.params.eventSlug);
  }

  componentDidUpdate() {
    if (!this.props.focusedEvent) {
      return
    }
    const checkinId = parseInt(this.props.match.params.checkin, 10);
    if (checkinId) {
      this.props.focusCheckin(checkinId);
    } else {
      this.props.unfocusCheckin();
    }
  }

  render() {
    if (!this.props.focusedEvent) {
      return false;
    }

    // The considtional rendering of Checkin/Checkins should be replaced by
    // Switch and Route from react-router v4. The problem is that Checkin and
    // Checkins expects this Event component to set up focusedEvent and
    // focusedCheckin for them. I think that could (and should) be handled by
    // the redux store but that will require a more extensive rewrite than I
    // want to attempt while I'm knee-deep in upgrading packages.
    return (
      <div className="event">
        <DynamicStyles />

        <ErrorBoundary>
          {this.props.focusedCheckin && <Checkin {...this.props} />}
          {!this.props.focusedCheckin && <Checkins {...this.props} />}
        </ErrorBoundary>
      </div>
    );
  }
}
