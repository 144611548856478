import load from 'photobooth/router';

// this allows old urls to work
// redirects /#/... to /...
if (window.location.href.match(/\/#\//)) {
  if (window.history || window.history.pushState) {
    window.location = window.location.hash.replace(/^#/, '');
  }
}

load();
