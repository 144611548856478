import fetch from 'isomorphic-fetch';

export const NEW_CHECKINS = 'NEW_CHECKINS';
function newCheckins(checkins) {
  return {
    type: NEW_CHECKINS,
    checkins,
  };
}

export function fetchNewCheckins(eventSlug, newerThan) {
  return function fetchNewCheckinsDispatcher(dispatch) {
    const eventCheckinsUrl = `${
      __ENV__.API_BASE_URL
    }/events/${eventSlug}/event_checkins.json?newer_than=${newerThan}`;
    return fetch(eventCheckinsUrl)
      .then(response => response.json())
      .then(json => {
        if (json.event_checkins.length > 0) {
          dispatch(newCheckins(json.event_checkins))
        }
      });
  };
}
