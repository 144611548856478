import {findIndex,find} from 'lodash-es';
import moment from 'moment';

const events = (state, action) => {
  if (action.type === 'RECEIVE_EVENTS') {
    const mappedEvents = action.events.map(event => {
      const formattedLastCheckinAt = moment(event.last_checkin_at).format(
        'MMMM D, YYYY'
      );
      return { ...event, formattedLastCheckinAt };
    });

    return mappedEvents.sort((a, b) => {
      if (moment(a.last_checkin_at) < moment(b.last_checkin_at)) {
        return 1;
      }
      if (moment(a.last_checkin_at) > moment(b.last_checkin_at)) {
        return -1;
      }
      return 0;
    });
  }
  return state;
};

const focusedEvent = (state, action) => {
  if (action.type === 'UNFOCUS_EVENT') {
    return false;
  }
  if (action.type === 'FOCUS_EVENT') {
    const { event } = action;
    event.formattedCreatedAt = moment(event.created_at).format('MMMM D, YYYY');
    return event;
  }
  if (action.type === 'NEW_CHECKINS') {
    const newCheckins = action.checkins;
    const event = state.focusedEvent;
    if (action.checkins.length === 0) {
      return event;
    }
    const currentIndex = findIndex(
      event.event_checkins,
      checkin => checkin.id === state.focusedCheckin.id
    );
    event.event_checkins.splice(currentIndex, 0, ...newCheckins);
    return event;
  }
  return state.focusedEvent;
};

const transitionToCheckin = (state, action) => {
  if (action.type === 'RESET_CHECKIN_TRANSITION') {
    return false;
  }
  if (action.type === 'KEYPRESS' && state.focusedCheckin) {
    if (action.key === 'ArrowLeft') {
      return state.checkinSiblings.previous;
    }
    if (action.key === 'ArrowRight') {
      return state.checkinSiblings.next;
    }
  }
  return state.transitionToCheckin;
};

const focusedCheckin = (state, action) => {
  if (action.type === 'UNFOCUS_CHECKIN') {
    return false;
  }
  if (action.type === 'FOCUS_CHECKIN') {
    const foundFocusedCheckin = find(
      state.focusedEvent.event_checkins,
      checkin => checkin.id === action.checkinId
    );
    foundFocusedCheckin.formattedTime = moment(
      foundFocusedCheckin.created_at
    ).format('MMMM D[,] YYYY [at] h:mm a');
    return foundFocusedCheckin;
  }
  return state.foundFocusedCheckin;
};

const checkinSiblings = (state, action) => {
  if (action.type === 'NEW_CHECKINS') {
    if (action.checkins.length !== 0) {
      return {
        previous: state.checkinSiblings.previous,
        next: action.checkins[0].id,
      };
    }
  }
  if (action.type === 'FOCUS_CHECKIN') {
    const checkins = state.focusedEvent.event_checkins;
    const currentIndex = findIndex(
      checkins,
      checkin => checkin.id === action.checkinId
    );
    const nextCheckin = checkins[currentIndex + 1];
    const previousCheckin = checkins[currentIndex - 1];
    return {
      next: nextCheckin ? nextCheckin.id : false,
      previous: previousCheckin ? previousCheckin.id : false,
    };
  }
  return state.checkinSiblings;
};

const partyMode = (state, action) => {
  if (action.type === 'TOGGLE_PARTY_MODE') {
    return !state.partyMode;
  }
  return state.partyMode;
};

const initialState = {
  events: [],
  focusedEvent: undefined,
  focusedCheckin: undefined,
  checkinSiblings: {
    next: undefined,
    previous: undefined,
  },
  transitionToCheckin: undefined,
  partyMode: false,
};

const App = (state = initialState, action) => {
  const AppState = {
    events: events(state.events, action),
    focusedEvent: focusedEvent(state, action),
    focusedCheckin: focusedCheckin(state, action),
    checkinSiblings: checkinSiblings(state, action),
    transitionToCheckin: transitionToCheckin(state, action),
    partyMode: partyMode(state, action),
  };
  return AppState;
};

export default App;
