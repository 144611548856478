import React from 'react';

import './styles.scss';

import twitterIcon from 'photobooth/assets/images/icons/twitter.svg';
import facebookIcon from 'photobooth/assets/images/icons/facebook.svg';
import instagramIcon from 'photobooth/assets/images/icons/instagram.svg';
import githubIcon from 'photobooth/assets/images/icons/github.svg';
import dribbbleIcon from 'photobooth/assets/images/icons/dribbble.svg';
import linkedinIcon from 'photobooth/assets/images/icons/linkedin.svg';

class Footer extends React.Component {
  static displayName = 'footer';

  render() {
    return (
      <div className="footer">
        <div className="footer-inner">
          <a className="footer-logo" href="http://substantial.com">
            Visit substantial.com
          </a>
          <div className="footer-locations">
            <div
              itemType="http://schema.org/PostalAddress"
              itemScope=""
              className="footer-location"
            >
              <a
                title="Link opens in a new window."
                target="_blank"
                rel="noopener noreferrer"
                className="footer-location-address"
                href="https://www.google.com/maps/place/900+E+Pine+St+%23202,+Seattle,+WA+98122"
              >
                <div itemProp="streetAddress">900 E Pine St, Suite 202</div>
                <div>
                  <span itemProp="addressLocality"> Seattle</span>,
                  <span itemProp="addressRegion"> WA</span>
                  <span itemProp="postalCode"> 98122</span>
                </div>
                <div
                  content="+12068380303"
                  itemProp="telephone"
                  className="offscreen"
                >
                  206 838 0303
                </div>
              </a>
            </div>
          </div>
          <div className="footer-contact-information">
            <ul>
              <li>
                <a
                  title="Link opens in a new window."
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:info@substantial.com"
                >
                  info@substantial.com
                </a>
              </li>
              <li>
                SEA <a href="tel:206-838-0303">206 838 0303</a>
              </li>
            </ul>
          </div>

          <div className="footer-social-media">
            <ul>
              <li>
                <a
                  title="Link opens in a new window."
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://twitter.com/substantial"
                >
                  <img src={twitterIcon} />
                </a>
              </li>
              <li>
                <a
                  title="Link opens in a new window."
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/wearesubstantial"
                >
                  <img src={facebookIcon} />
                </a>
              </li>
              <li>
                <a
                  title="Link opens in a new window."
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://instagram.com/substantial"
                >
                  <img src={instagramIcon} />
                </a>
              </li>
              <li>
                <a
                  title="Link opens in a new window."
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://github.com/substantial"
                >
                  <img src={githubIcon} />
                </a>
              </li>
              <li>
                <a
                  title="Link opens in a new window."
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dribbble.com/substantial"
                >
                  <img src={dribbbleIcon} />
                </a>
              </li>
              <li>
                <a
                  title="Link opens in a new window."
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/substantial"
                >
                  <img src={linkedinIcon} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
