import React from 'react';
import PropTypes from 'prop-types';

// I created this component as a quick experiment after upgrading to React 16.
// It doesn't really do anything except prevent the UI from crashing in some
// cases. It can be left as is, expanded on, or removed entirely as you see fit.
class ErrorBoundry extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  componentDidCatch(_error, _errorInfo) {}

  render() {
    return this.props.children;
  }
}

export default ErrorBoundry;
