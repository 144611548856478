import DynamicStyles from 'photobooth/containers/dynamic-styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import './styles';

export default class extends React.Component {
  static displayName = 'events';

  static propTypes = {
    requestEvents: PropTypes.func.isRequired,
    unfocusEvent: PropTypes.func.isRequired,
    unfocusCheckin: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
  };

  componentWillMount() {
    this.props.unfocusEvent();
    this.props.unfocusCheckin();
    this.props.requestEvents();
  }

  render() {
    const renderEvent = event => {
      const key = `key-${event.id}`;
      const styles = {
        borderColor: event.cover_accent,
      };
      return (
        <li className="event" key={key}>
          <Link to={`/events/${event.slug}`}>
            <div className="highlight-overlay" style={styles} />
            <div className="event-details">
              <img className="event-cover" src={event.cover} />
              <h3 className="event-title">{event.name}</h3>
              <h5 className="event-last-checkin">
                {event.formattedLastCheckinAt}
              </h5>
            </div>
          </Link>
        </li>
      );
    };
    return (
      <div className="events">
        <DynamicStyles />
        <ul>{this.props.events.map(renderEvent)}</ul>
      </div>
    );
  }
}
