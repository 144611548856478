import { connect } from 'react-redux';

import component from 'photobooth/components/header';

function mapStateToProps(state) {
  return {
    focusedEvent: state.focusedEvent,
    focusedCheckin: state.focusedCheckin,
    partyMode: state.partyMode,
  };
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
