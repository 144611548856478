import { connect } from 'react-redux';

import component from 'photobooth/components/event';
import { fetchEvent } from 'photobooth/actions/event';
import { unfocusCheckin, focusCheckin } from 'photobooth/actions/checkin';

function mapStateToProps(state) {
  return {
    focusedEvent: state.focusedEvent,
    focusedCheckin: state.focusedCheckin,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEvent: eventSlug => dispatch(fetchEvent(eventSlug)),
    focusCheckin: checkinId => dispatch(focusCheckin(checkinId)),
    unfocusCheckin: () => dispatch(unfocusCheckin()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
