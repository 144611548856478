import { connect } from 'react-redux';
import { fetchNewCheckins } from 'photobooth/actions/checkins';
import component from 'photobooth/components/party-mode';

function mapStateToProps(state) {
  return {
    focusedEvent: state.focusedEvent,
    partyMode: state.partyMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNewCheckins: (eventSlug, newerThan) => {
      dispatch(fetchNewCheckins(eventSlug, newerThan));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
