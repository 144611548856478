import fetch from 'isomorphic-fetch';

export const REQUEST_EVENT = 'REQUEST_EVENT';
function requestEvent() {
  return {
    type: REQUEST_EVENT,
  };
}

export const FOCUS_EVENT = 'FOCUS_EVENT';
function focusEvent(event) {
  return {
    type: FOCUS_EVENT,
    event,
  };
}

export function fetchEvent(eventSlug) {
  return function fetchEventDispatcher(dispatch) {
    dispatch(requestEvent());
    const eventUrl = `${__ENV__.API_BASE_URL}/events/${eventSlug}.json`;
    return fetch(eventUrl)
      .then(response => response.json())
      .then(json => dispatch(focusEvent(json.event)));
  };
}
