import PropTypes from 'prop-types';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import classnames from 'classnames';

import Header from 'photobooth/containers/header';
import Footer from 'photobooth/components/footer';
import EventsContainer from 'photobooth/containers/events';
import EventContainer from 'photobooth/containers/event';

import './styles.scss';

class App extends React.Component {
  static displayName = 'app';

  static propTypes = {
    togglePartyMode: PropTypes.func.isRequired,
    keyPress: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    partyMode: PropTypes.bool.isRequired,

    // These `oneOfType` checks are gross and are only meant to document
    // preexisting behavior. You'll find them in a few other components as well.
    focusedEvent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    focusedCheckin: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static defaultProps = {
    focusedEvent: undefined,
    focusedCheckin: undefined,
  };

  componentDidMount() {
    if (this.props.location.search.indexOf('party') > -1) {
      this.props.togglePartyMode();
    }
    document.addEventListener('keydown', this.props.keyPress);
  }

  componentDidUnMount = () => {
    document.removeEventListener('keydown', this.props.keyPress);
  };

  render() {
    const className = classnames({
      app: true,
      'focused-event': this.props.focusedEvent,
      'focused-checkin': this.props.focusedCheckin,
      'party-mode': this.props.partyMode,
      'white-label':
        this.props.focusedEvent && this.props.focusedEvent.white_label,
    });

    return (
      <div className={className}>
        <div className="header-accent header-background">
          <Header />
        </div>
        <div className="handler body-accent body-background">
          <Switch>
            <Route exact path="/" component={EventsContainer} />
            <Route
              path="/events/:eventSlug/checkins/:checkin"
              component={EventContainer}
            />
            <Route path="/events/:eventSlug" component={EventContainer} />
            <Route path="/events" component={EventsContainer} />
          </Switch>
        </div>
        <Footer />
      </div>
    );
  }
}

export default App;
