import PropTypes from 'prop-types';
import React from 'react';

export default class extends React.Component {
  static displayName = 'dynamic-styles';

  static propTypes = {
    focusedEvent: PropTypes.object,
  };

  static defaultProps = {
    focusedEvent: undefined,
  };

  addFocusedEventStyles = () => {
    let focusedEventStyles = '';
    const headerAccent = this.props.focusedEvent.header_accent;
    const bodyAccent = this.props.focusedEvent.body_accent;
    const headerBackground = this.props.focusedEvent.header_background;
    const bodyBackground = this.props.focusedEvent.body_background;
    const logo = this.props.focusedEvent.logo;
    focusedEventStyles += `.header-accent, .header-accent a { color: ${headerAccent} !important; }`;
    focusedEventStyles += `.body-accent, .body-accent a { color: ${bodyAccent} !important;}`;
    focusedEventStyles += `.party-mode .header, .header-background { background: ${headerBackground} !important;}`;
    focusedEventStyles += `.party-mode .checkin-identity, .party-mode .checkin-identity a { color: ${headerAccent} !important;}`;
    focusedEventStyles += `body { background: ${bodyBackground} !important;}`;
    focusedEventStyles += `.logo { background: url(${logo}) !important;}`;
    return focusedEventStyles;
  };

  render() {
    let styles = `.app { display: block !important; }`;

    if (this.props.focusedEvent) {
      styles += this.addFocusedEventStyles();
    }

    return <style>{styles}</style>;
  }
}
