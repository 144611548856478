import { connect } from 'react-redux';

import component from 'photobooth/components/checkin';
import {
  focusCheckin,
  resetCheckinTransition,
} from 'photobooth/actions/checkin';

function mapStateToProps(state) {
  return {
    focusedEvent: state.focusedEvent,
    focusedCheckin: state.focusedCheckin,
    checkinSiblings: state.checkinSiblings,
    transitionToCheckin: state.transitionToCheckin,
    partyMode: state.partyMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    focusCheckin: checkinId => dispatch(focusCheckin(checkinId)),
    resetCheckinTransition: () => dispatch(resetCheckinTransition()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(component);
