import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import PartyMode from 'photobooth/containers/party-mode';

import './styles';

const interval = 5000

class Checkin extends React.Component {
  static propTypes = {
    resetCheckinTransition: PropTypes.func.isRequired,
    transitionToCheckin: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
    ]),
    focusedEvent: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
      .isRequired,
    focusedCheckin: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
      .isRequired,
    checkinSiblings: PropTypes.object.isRequired,
    partyMode: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    transitionToCheckin: undefined,
  };

  componentDidMount() {
    if (this.props.partyMode) {
      this.interval = setInterval(this.transitionToNext, interval);
    }
  }

  componentDidUpdate() {
    if (
      this.props.transitionToCheckin &&
      this.props.transitionToCheckin !== this.props.focusedCheckin.id
    ) {
      this.transitionToCheckin(this.props.transitionToCheckin);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  transitionToNext = () => {
    if (this.props.checkinSiblings.next) {
      this.transitionToCheckin(this.props.checkinSiblings.next);
    } else {
      const checkins = this.props.focusedEvent.event_checkins;
      const firstCheckin = checkins[0];
      this.transitionToCheckin(firstCheckin.id);
    }
  };

  transitionToPrevious = () => {
    if (this.props.checkinSiblings.previous) {
      this.transitionToCheckin(this.props.checkinSiblings.previous);
    } else {
      const checkins = this.props.focusedEvent.event_checkins;
      const lastCheckin = checkins[checkins.length - 1];
      this.transitionToCheckin(lastCheckin.id);
    }
  };

  transitionToCheckin = id => {
    this.props.resetCheckinTransition();
    this.props.history.push(
      `/events/${this.props.focusedEvent.slug}/checkins/${id}`
    );
  };

  render() {
    if (!this.props.focusedCheckin || !this.props.checkinSiblings) {
      return false;
    }

    let nextLink;
    if (this.props.checkinSiblings.next) {
      nextLink = (
        <button className="body-accent" onClick={this.transitionToNext}>
          <span className="checkin-navigation-icon" />
        </button>
      );
    }

    let previousLink;
    if (this.props.checkinSiblings.previous) {
      previousLink = (
        <button className="body-accent" onClick={this.transitionToPrevious}>
          <span className="checkin-navigation-icon" />
        </button>
      );
    }

    let name;
    if (this.props.focusedCheckin.name) {
      name = (
        <span className="checkin-name">{this.props.focusedCheckin.name}</span>
      );
    }

    let twitter;
    if (this.props.focusedCheckin.twitter_name) {
      twitter = (
        <a
          className="checkin-twitter"
          href={`http://twitter.com/${this.props.focusedCheckin.twitter_name}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {this.props.focusedCheckin.twitter_pronoun}
        </a>
      );
    }

    let separator;
    if (name && twitter) {
      separator = <span className="checkin-identity-separator">&middot;</span>;
    }

    let imgSrc;
    if (this.props.partyMode) {
      imgSrc = this.props.focusedCheckin.image.original;
    } else {
      imgSrc = this.props.focusedCheckin.image.watermarked;
    }

    return (
      <div className="checkin-detail">
        <PartyMode />
        <div className="checkin-inner">
          <div className="checkin-navigation-previous body-accent">
            {previousLink}
          </div>
          <div className="checkin-details">
            <img className="checkin-image" src={imgSrc} />
            <div className="checkin-metadata">
              <div className="checkin-identity">
                {name}
                {separator}
                {twitter}
              </div>
              <div className="checkin-time">
                {this.props.focusedCheckin.formattedTime}
              </div>
            </div>
          </div>
          <div className="checkin-navigation-next body-accent">{nextLink}</div>
        </div>
        <div className="checkin-back-to-gallery">
          <Link to={`/events/${this.props.focusedEvent.slug}`}>
            Back To Gallery
          </Link>
        </div>
      </div>
    );
  }
}

export default Checkin;
