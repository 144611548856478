import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import component from 'photobooth/components/app';
import { keyPress, togglePartyMode } from 'photobooth/actions/app';

function mapStateToProps(state) {
  return {
    focusedEvent: state.focusedEvent,
    focusedCheckin: state.focusedCheckin,
    partyMode: state.partyMode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    togglePartyMode: () => dispatch(togglePartyMode()),
    keyPress: key => dispatch(keyPress(key)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(component)
);
