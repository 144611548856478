import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Preload } from 'photobooth/components/preload';

import './styles';

export default class extends React.Component {
  static displayName = 'checkins';

  static propTypes = {
    focusedEvent: PropTypes.object.isRequired,
  };

  state = {
    hoveredCheckin: null,
  };

  handleMouseOver(checkin) {
    this.setState({ hoveredCheckin: checkin });
  };

  handleMouseOut() {
    this.setState({ hoveredCheckin: null });
  };

  render() {
    function renderCheckin(checkin) {
      return (
        <li className="checkin" key={`checkin-${checkin.id}`}>
          <Link
            to={`/events/${this.props.focusedEvent.slug}/checkins/${
              checkin.id
            }`}
          >
            <img
              src={
                this.state.hoveredCheckin === checkin
                  ? checkin.image.watermarked
                  : checkin.image.static
              }
              onMouseOver={this.handleMouseOver.bind(this, checkin)}
              onMouseOut={this.handleMouseOut.bind(this, checkin)}
            />
          </Link>
        </li>
      );
    }
    function watermarkedUrl(checkin) {
      return checkin.image.watermarked;
    }
    const finalDisplayContent = (
      <ul className="checkins">
        {this.props.focusedEvent.event_checkins.map(renderCheckin, this)}
      </ul>
    );
    const images = this.props.focusedEvent.event_checkins.map(
      watermarkedUrl,
      this
    );

    return (
      <Preload
        loadingIndicator={finalDisplayContent}
        images={images}
        autoResolveDelay={3000}
        resolveOnError
        mountChildren
      >
        {finalDisplayContent}
      </Preload>
    );
  }
}
