const keyMap = {
  39: 'ArrowRight',
  37: 'ArrowLeft',
};

export const KEYPRESS = 'KEYPRESS';
export function keyPress(e) {
  return function keyPressDispatcher(dispatch) {
    const pressed = keyMap[e.keyCode];
    if (pressed) {
      e.preventDefault();
      dispatch({
        type: KEYPRESS,
        key: pressed,
      });
    }
  };
}

export const TOGGLE_PARTY_MODE = 'TOGGLE_PARTY_MODE';
export function togglePartyMode() {
  return function togglePartyModeDispatcher(dispatch) {
    dispatch({
      type: TOGGLE_PARTY_MODE,
    });
  };
}
