import fetch from 'isomorphic-fetch';

export const REQUEST_EVENTS = 'REQUEST_EVENTS';
function requestEvents() {
  return {
    type: REQUEST_EVENTS,
  };
}

export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
function receiveEvents(json) {
  const events = json.events;
  return {
    type: RECEIVE_EVENTS,
    events,
  };
}

export const UNFOCUS_EVENT = 'UNFOCUS_EVENT';
export function unfocusEvent() {
  return {
    type: UNFOCUS_EVENT,
  };
}

export function fetchEvents() {
  return function fetchEventsDispatcher(dispatch) {
    dispatch(requestEvents());
    const eventsUrl = `${__ENV__.API_BASE_URL}/events.json`;
    return fetch(eventsUrl)
      .then(response => response.json())
      .then(json => dispatch(receiveEvents(json)));
  };
}
